import Viewcell from '@/models/Viewcell';
import LocalTime from '@/utility/local_time';
import DEFAULT_PLAYBACK_VIEWCELL from '@/constants/defaultPlaybackViewCell';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const playback = {
  namespaced: true,
  state: {
    layout: '1x1',
    view: [
      new Viewcell(JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL))),
    ],
    activeEncoderId: -1,
    selectedDate: new LocalTime(0).useUniTimezone(),
  },
  getters,
  actions,
  mutations,
};

export default playback;
